import { definedNotNull } from 'shared/presenters/presenter'

export interface MoneyProps {
  /**
   * Takes a whole number, corresponds to the amount to be formatted
   */
  pence: number
  /**
   * Takes a 3 character currency string
   */
  currency: string
  /**
   * Round money up to a whole unit, not displaying decimals
   */
  wholeNumber?: boolean
  showCurrencySymbol?: boolean
  /**
   * Takes a 2 character locale string
   */
  locale?: string
  /**
   * Whether to use grouping separators, such as thousands separators
   */
  groupNumbers?: boolean
}

/**
 * Deals with money and currency conversion.
 * It will fetch the value and format it to the user locale.
 */
const money = ({
  pence,
  currency,
  /**
   * Return a whole number if pence is 0.
   */
  wholeNumber = !pence,
  showCurrencySymbol = true,
  locale,
  groupNumbers = true
}: MoneyProps): string => {
  const formatter = new Intl.NumberFormat(locale || undefined, {
    style: showCurrencySymbol ? 'currency' : 'decimal',
    currency: currency?.toUpperCase(),
    maximumFractionDigits: wholeNumber ? 0 : 2,
    minimumFractionDigits: wholeNumber ? 0 : 2,
    useGrouping: groupNumbers
  })

  const priceAsDecimal = definedNotNull(pence) / 100

  return formatter.format(
    wholeNumber ? Math.ceil(priceAsDecimal) : priceAsDecimal
  )
}

/**
 * NOTE: This function assumes the currency symbol is a single character
 */
export const getCurrencySymbol = (currency = 'gbp') => {
  return money({ pence: 0, currency: currency, wholeNumber: false })[0]
}

export default money
