import { props as systemProps } from '@styled-system/should-forward-prop'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = { [k: string]: any }

/**
 * Retrieves props related to styled-system.
 *
 * Useful for allocating styled-system props to a specific element without the use of "...props".
 *
 * Example:
 * <div {...getSystemProps(props)}>
 *   <span {...otherProps} />
 * </div>
 */

const getSystemProps = (props: Props) => {
  const selectedProps: Props = {}
  for (const key in props) {
    if (systemProps.some(item => item === key)) {
      selectedProps[key] = props[key]
    }
  }
  return selectedProps
}

export default getSystemProps
