/**
 * Formik's field.onChange method when called with a name returns a function or when called with an object (that looks like an event) just makes
 * the onChange request as you would expect.
 *
 * Most of our implementations of onChange use an event, so in an effort to homogenize our implementation strategy to reduce complexity in our form
 * abstraction we have decided to force the event type to be used in all scenarios
 *
 * This helper helps create an event for when the native browser event is for whatever reason not available (this sometimes occurs in some of our
 * vendor components)
 */
const createSyntheticChangeEvent = ({
  value,
  name
}: {
  value: string | undefined
  name: string
}) => {
  const syntheticEvent = {
    // mimic the properties of a React SyntheticEvent
    bubbles: true,
    cancelable: true,
    currentTarget: null, // usually the same as target for synthetic events
    defaultPrevented: false,
    eventPhase: 2, // Event.AT_TARGET (might not be strictly necessary)
    isTrusted: false,
    nativeEvent: new Event('change'), // a native event object (not strictly necessary)
    // eslint-disable-next-line  @typescript-eslint/no-empty-function
    persist: () => {}, // noop function, React uses this to retain the event data
    // eslint-disable-next-line  @typescript-eslint/no-empty-function
    preventDefault: () => {},
    // eslint-disable-next-line  @typescript-eslint/no-empty-function
    stopPropagation: () => {},
    isDefaultPrevented: () => false,
    isPropagationStopped: () => false,
    timeStamp: Date.now(),
    type: 'change',
    target: {
      // mimic the target of a change event on an input
      type: 'text', // appropriate type
      name: name, // your input's name
      id: name, // your input's ID
      value: value ?? '', // telephone number
      checked: false, // for checkboxes, otherwise can be ignored
      outerHTML: '', // not usually used in logic, but just in case
      options: null, // relevant for select inputs
      multiple: false // relevant for select or file inputs
    }
  }

  return syntheticEvent
}

export default createSyntheticChangeEvent
