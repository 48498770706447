import {
  Formik,
  useFormikContext,
  FormikHelpers,
  FormikConfig,
  FormikErrors,
  FormikContextType,
  FormikProps,
  useField,
  useFormik
} from 'formik'

/**
 * These overrides of the original formik helpers and types are used to provide
 * two main improvements:
 *
 * - Control over the type of `status` and associated properties. These are defined as `any`,
 *   which can easily result in misuse. This now means that `status` can only be used to display
 *   errors in forms.
 *
 * - The addition of `_unmappedError`, which can be used to display errors that are not
 *   one of the existing Form fields e.g. a permission based error. This is not currently
 *   used.
 */

type ExtendedFormikValues<T> = T & { _unmappedError?: string }

type ExtendedFormikProps<T> = FormikProps<ExtendedFormikValues<T>>

type ExtendedFormikErrors<F> = FormikErrors<ExtendedFormikValues<F>>

export type Status<T> = {
  errors?: ExtendedFormikErrors<T>
}

type ExtendedStatusProperties<T> = Omit<
  FormikContextType<ExtendedFormikValues<T>>,
  'status' | 'initialStatus'
> & {
  status?: Status<T>
  initialStatus?: Status<T>
}

type ExtendedFormikStatusHelpers<T> = Omit<
  FormikHelpers<ExtendedFormikValues<T>>,
  'setStatus'
> & {
  setStatus: (status?: Status<T>) => void
}

type ExtendedFormikHelpers<T> = Omit<
  ExtendedFormikStatusHelpers<T>,
  'onSubmit'
> & {
  onSubmit: (
    values: ExtendedFormikValues<T>,
    helpers: ExtendedFormikStatusHelpers<T>
  ) => void
}

type ExtendedFormikContextType<T> = Omit<
  FormikContextType<ExtendedFormikValues<T>>,
  'status' | 'initialStatus' | 'setStatus' | 'onSubmit'
> &
  ExtendedFormikStatusHelpers<T> &
  ExtendedStatusProperties<T>

type ExtendedFormikConfig<T> = FormikConfig<ExtendedFormikValues<T>>

const useExtendedFormikContext = <T,>(): ExtendedFormikContextType<T> =>
  useFormikContext<ExtendedFormikValues<T>>()

const useExtendedFormik = <T,>(
  props: ExtendedFormikConfig<T>
): ExtendedFormikContextType<T> => useFormik<ExtendedFormikValues<T>>(props)

const ExtendedFormik = <T,>(props: ExtendedFormikConfig<T>) => {
  return <Formik<ExtendedFormikValues<T>> {...props} />
}

export {
  ExtendedFormik as Formik,
  useExtendedFormikContext as useFormikContext,
  useExtendedFormik as useFormik,
  useField
}

export type {
  ExtendedFormikValues as FormikValues,
  ExtendedFormikHelpers as FormikHelpers,
  ExtendedFormikErrors as FormikErrors,
  ExtendedFormikConfig as FormikConfig,
  ExtendedFormikProps as FormikProps,
  ExtendedFormikContextType as FormikContextType
}
