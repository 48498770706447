import React, { useCallback } from 'react'
import {
  Formik,
  FormikConfig,
  Status,
  useField,
  useFormikContext,
  useFormik
} from 'shared/helpers/extendedFormikContext'
import {
  useForm,
  FormProvider,
  FieldValues,
  useFormContext,
  FieldPath,
  ErrorOption,
  Path,
  useFieldArray,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn,
  FieldPathValue
} from 'react-hook-form'
import * as Yup from 'yup'
import { FormikProvider } from 'formik'

/**
 * This file was created to keep form library implementation details out of our
 * application logic. By doing this, we can migrate form libraries without having to
 * reconstruct/modify all of our existing forms and components. This set of form methods
 * are compatible with formik and RHF (react-hook-form).
 *
 * All new form usages should use the form methods/compoents imported from this file
 *
 * e.g.
 * ❌ import { Formik, useFormikContext } from "formik"
 * ✅ import { FormProvider, useFormContext } from "shared/providers/FormProvider"
 *
 * We have a few different form implementations within our apps. Here are some examples of how to migrate
 * from Formik to these generic methods/components:
 *
 * - Using the <Formik> component: This component can be replaced with the <FormProvider> component.
 * - Using withFormik HOC: There is no alternative to this right now. We should instead move to using a different approach.
 * - Using useFormik with <FormikProvider>: We can use useForm and <FormContextProvider> instead. This approach is not commonly used,
 * as Jared has not worked on this codebase, but is a good alternative to the above approaches. It allows us to seperate form configuration and context.
 *
 * Future work:
 *
 * Right now, most of this file is compatible with two form libraries, Formik and react-hook-form.
 *
 * If we decide to move away from Formik, we can finish compatibility with react-hook-form or whatever
 * other library we choose in this file.
 *
 * If we decide to retain Formik, there may only be some small updates that are required.
 * e.g. returning additional props when we map from a formik response to a generic one.
 *
 * Either way, we could split out specific form implementations as 'adaptors', making this file
 * easier to comprehend.
 *
 * The only other work required is to migrate to these methods fully, avoiding direct imports from "formik"
 * in our codebase.
 *
 */

export enum FORM_PROVIDER {
  formik = 'formik',
  reactHookForm = 'react-hook-form'
}

/**
 * This was nabbed from the RHF documentation, and provides a method
 * of using Yup validation with RHF.
 */
const useYupValidationResolver = (
  validationSchema: Yup.ObjectSchema<Yup.AnyObject>
) =>
  useCallback(
    async (data: unknown) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        })

        return {
          values,
          errors: {}
        }
      } catch (errors: unknown) {
        if (errors instanceof Yup.ValidationError) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path ?? 'unknown']: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message
                }
              }),
              {}
            )
          }
        }
        return {
          values: {},
          errors: {}
        }
      }
    },
    [validationSchema]
  )

type SetErrorFieldPath<T extends FieldValues> =
  | FieldPath<T>
  | `root.${string}`
  | 'root'

/**
 * This type provides a consistent return type for both
 * RHF and formik forms
 */
type UseFormContextReturnType<T extends FieldValues> = {
  register: (name: Path<T>) => UseFormRegisterReturn<Path<T>> | undefined
  values: T
  setValue: (
    fieldName: Path<T>,
    value: FieldPathValue<T, typeof fieldName>
  ) => void
  setError: (fieldName: SetErrorFieldPath<T>, error: ErrorOption) => void
  reset: () => void
  formState: {
    isDirty: boolean
    isValid: boolean
    isSubmitting: boolean
  }
  status?: Status<T> | undefined
  _contextProvider: FORM_PROVIDER
}

const useFormContextCustom = <
  T extends FieldValues
>(): UseFormContextReturnType<T> => {
  const rhfContext = useFormContext<T>()
  const formikContext = useFormikContext<T>()

  if (rhfContext != null) {
    return {
      register: rhfContext.register,
      values: rhfContext.watch(),
      setValue: rhfContext.setValue,
      setError: rhfContext.setError,
      reset: rhfContext.reset,
      formState: {
        isDirty: rhfContext.formState.isDirty,
        isValid: rhfContext.formState.isValid,
        isSubmitting: rhfContext.formState.isSubmitting
      },
      // TODO - if/when we eventually migrate over to RHF we need to find a good way of feeding backend errors via this status prop
      status: undefined,
      _contextProvider: FORM_PROVIDER.reactHookForm
    }
  }

  if (formikContext != null) {
    return {
      register: () => undefined,
      values: formikContext.values,
      setValue: formikContext.setFieldValue,
      setError: (fieldName: SetErrorFieldPath<T>, error: ErrorOption) =>
        formikContext.setFieldError(fieldName, error.message),
      reset: formikContext.resetForm,
      formState: {
        isDirty: formikContext.dirty,
        isValid: formikContext.isValid,
        isSubmitting: formikContext.isSubmitting
      },
      status: formikContext.status,
      _contextProvider: FORM_PROVIDER.formik
    }
  }

  throw new Error('useFormContext used outside of available contexts.')
}

const useFieldCustom = <T,>(name: string) => {
  /**
   * Check for RHF context first. If this doesn't exist, check
   * for formik context.
   */
  const rhfContext = useFormContext()
  let formikUseField = null
  let formikContext = null

  /**
   * We are inferring from whether the context object exists or not what form library the page is using
   * However, in the case of Formik, if you check for Formik's context and it doesn't exist then we see runtime errors
   * Therefore we are try catching here to soften the error so we can continue
   */
  try {
    //eslint-disable-next-line  react-hooks/rules-of-hooks
    formikUseField = useField<T>(name)
    //eslint-disable-next-line  react-hooks/rules-of-hooks
    formikContext = useFormikContext()
  } catch (err) {
    console.warn('Cannot read from formik context context...', err)
  }

  if (rhfContext != null) {
    const fieldState = rhfContext.getFieldState(name)
    const fieldValue = rhfContext.watch(name)

    const registered = rhfContext.register(name)
    console.warn(
      'You are using the RHF context. Note as of 09/2023 we have not introduced a mechanism for backend served field level errors for RHF form contexts via the status prop. Please fix this then remove this warning if you intend to use RHF going forwards in the project'
    )
    return {
      registered,
      field: {
        name,
        value: fieldValue as T,
        onChange: registered.onChange,
        onBlur: registered.onBlur,
        ref: registered.ref
      },
      meta: {
        error: fieldState.error?.message as string,
        touched: fieldState.isTouched,
        dirty: fieldState.isDirty,
        initialValue: null
      },
      helpers: {
        setValue: (value: T) => rhfContext.setValue(name, value),
        setTouched: () => {
          console.warn(
            'Warning - we do not have setTouched implemented yet within this form context'
          )
        }
      },
      status: {
        errors: {
          // todo: fix errors in RHF
          // e.g. [name]: 'firstName is too long'
        }
      } as Status<T>
    }
  }

  /**
   * We fetch formik context here to bundle in the status field.
   * This is used to store server errors in components.
   */
  if (formikUseField != null) {
    return {
      field: {
        ...formikUseField[0],
        value: formikUseField[0].value as T
      },
      meta: {
        ...formikUseField[1],
        touched: formikUseField[1].touched,
        dirty: formikUseField[1].initialValue !== formikUseField[1].value
      },
      helpers: formikUseField[2],
      status: formikContext?.status
    }
  }

  throw new Error('useField used outside of RHF or Fomik context.')
}

type FormBag<T extends FieldValues> = Pick<
  UseFormContextReturnType<T>,
  'values' | 'setValue'
>

type ReactHookFormProps<T extends FieldValues> = {
  formProvider: FORM_PROVIDER.reactHookForm
  initialValues: UseFormProps<T>['defaultValues']
  validationSchema: Yup.ObjectSchema<Yup.AnyObject>
  onSubmit?: (
    value: T,
    context: UseFormReturn<T, UseFormContextReturnType<T>>
  ) => void
}

type FormikProps<T extends FieldValues> = Omit<
  FormikConfig<T>,
  'onSubmit' | 'children'
> & {
  formProvider?: FORM_PROVIDER.formik
  onSubmit?: FormikConfig<T>['onSubmit']
}

type _FormProps<T extends FieldValues> = ReactHookFormProps<T> | FormikProps<T>

type _FormPropsWithChildren<T extends FieldValues> = _FormProps<T> & {
  children?: React.ReactNode | ((props: FormBag<T>) => React.ReactNode)
}

const CustomFormProvider = <T extends FieldValues>(
  props: _FormPropsWithChildren<T>
) => {
  const resolver = useYupValidationResolver(props.validationSchema)

  const formProps = useForm<T, UseFormContextReturnType<T>>({
    defaultValues:
      props.formProvider === FORM_PROVIDER.reactHookForm
        ? props.initialValues
        : undefined,
    resolver: resolver
  })

  if (props.formProvider === FORM_PROVIDER.reactHookForm) {
    /**
     * We pass formProps as the second argument to onSubmit to replicate
     * formik. This can be used to set backend validation errors in the
     * onSubmit .catch().
     * TODO: Create a consistent `formProps` argument between RHF and formik.
     */
    const onSubmitHandler = (data: T) =>
      props.onSubmit ? props.onSubmit(data, formProps) : () => undefined

    return (
      <FormProvider {...formProps}>
        <form onSubmit={formProps.handleSubmit(onSubmitHandler)}>
          {typeof props.children === 'function'
            ? props.children({
                values: formProps.watch(),
                setValue: formProps.setValue
              })
            : props.children}
        </form>
      </FormProvider>
    )
  }

  return (
    <Formik
      {...props}
      onSubmit={props.onSubmit ? props.onSubmit : () => undefined}
    >
      {formikProps => {
        return typeof props.children === 'function'
          ? props.children({
              values: formikProps.values,
              setValue: formikProps.setFieldValue
            })
          : props.children
      }}
    </Formik>
  )
}

/**
 * TODO: These types are temporary, as TypeScript is unable to infer the correct return
 * type based on the `formProvider` prop. For now, we can use these types to cast the result
 */
type ReactHookFormContext<T extends FieldValues> = Extract<
  ReturnType<typeof useFormCustom<T>>,
  { _contextProvider: FORM_PROVIDER.reactHookForm }
>
type FormikContext<T extends FieldValues> = Extract<
  ReturnType<typeof useFormCustom<T>>,
  { _contextProvider: FORM_PROVIDER.formik }
>

const useFormCustom = <T extends FieldValues>(props: _FormProps<T>) => {
  const resolver = useYupValidationResolver(props.validationSchema)

  if (props.formProvider === FORM_PROVIDER.reactHookForm) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const reactHookForm = useForm<T, UseFormContextReturnType<T>>({
      defaultValues: props.initialValues,
      resolver: resolver
    })

    return {
      ...reactHookForm,
      values: reactHookForm.watch(),
      _contextProvider: FORM_PROVIDER.reactHookForm as const
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formik = useFormik<T>({
    ...props,
    onSubmit: props.onSubmit ? props.onSubmit : () => undefined
  })

  return {
    ...formik,
    values: formik.values,
    _contextProvider: FORM_PROVIDER.formik as const
  }
}

type FormContextProvider<T extends FieldValues> =
  | {
      context: FormikContext<T>
      children: React.ReactNode
      formProvider?: FORM_PROVIDER.formik
    }
  | {
      context: ReactHookFormContext<T>
      children: React.ReactNode
      formProvider: FORM_PROVIDER.reactHookForm
    }

const useFormContextProvider = <T extends FieldValues>(
  props: FormContextProvider<T>
) => {
  if (props.formProvider === FORM_PROVIDER.reactHookForm) {
    throw new Error('reactHookForm FormContextProvider not implemented')
  }

  return <FormikProvider value={props.context}>{props.children}</FormikProvider>
}

export {
  useFieldCustom as useField,
  CustomFormProvider as FormProvider,
  useFormContextProvider as FormContextProvider,
  useFormContextCustom as useFormContext,
  useFormCustom as useForm,
  useFieldArray
}

export type { FieldValues, FormBag, ReactHookFormContext, FormikContext }
