import { useState, useEffect } from 'react'
import { ON_SERVER } from 'shared/enums/environment'
import { breakpoints } from 'assets/theme/theme'

/**
 * A simple hook that exposes the current window dimensions in
 * a convenient and next friendly manner
 */
const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getDimensions())
  const handleResize = () => setDimensions(getDimensions())

  useEffect(() => {
    if (!ON_SERVER) {
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ON_SERVER])

  return dimensions
}

export default useWindowDimensions

const getDimensions: () => {
  width: number | null
  height: number | null
  device: ReturnType<typeof determineDevice>
} = () => {
  const width = !ON_SERVER ? window.innerWidth : null
  const height = !ON_SERVER ? window.innerHeight : null

  return {
    width,
    height,
    device: determineDevice(width)
  }
}

export function determineDevice(width: number | null) {
  if (!width || width < breakpoints.md) {
    return 'mobile'
  } else if (width >= breakpoints.md && width < breakpoints.lg) {
    return 'tablet'
  } else {
    return 'desktop'
  }
}
