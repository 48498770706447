import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Text } from 'library/atoms/Typography'

interface ValidationErrorProps {
  children: ReactNode
}

/**
 * ValidationError is used on forms to show error messages next to the field.
 */

const ValidationError = ({ children }: ValidationErrorProps) => {
  return (
    <StyledError size="xs" role="alert">
      {children}
    </StyledError>
  )
}

export default ValidationError

const StyledError = styled(Text)(({ theme }) => ({
  color: theme.colors.state.error,
  marginTop: theme.space[1],
  marginBottom: theme.space[2],
  ...theme.mixins.capitalizeFirstLetter()
}))
