import styled from '@emotion/styled'
import React, { FocusEvent } from 'react'
import { ModalProps } from 'shared/library/molecules/Modal'
import usePageRouter from 'shared/state/usePageRouter'
import Input, { InputCoreProps, InputLargeVariant } from './Input'

interface InputCalendarProps
  extends Omit<InputCoreProps, 'placeholder'>,
    Pick<InputLargeVariant, 'variant' | 'placeholder'> {
  calendarModalKey: Exclude<ModalProps['modalKey'], undefined>
}

/**
 * The InputCalendar combines an Input component with the extra logic to show and hide
 * modal-based events triggered by click and focus.
 * The Modal is not built-in here because modals should always be declared and managed
 * by the useModal.
 */
export const InputCalendar = ({
  variant = 'large',
  name,
  className,
  placeholder,
  calendarModalKey
}: InputCalendarProps) => {
  const { activeModalId, setActiveModalId } = usePageRouter()

  const handleFocus = ({
    e,
    showModal
  }: {
    e: FocusEvent<HTMLInputElement, Element>
    showModal: boolean
  }) => {
    e.preventDefault() // Disable datepicker for HTML 5 Input type='date'
    e.type = showModal ? 'date' : 'text' // Change to text so the placeholder can show non-dates.
  }

  return (
    <StyledInput
      name={name}
      variant={variant}
      icon="calendar"
      externallyControlled={true}
      placeholder={placeholder}
      onFocus={e => {
        /**
         * Check if the calendar modal is opened already to prevent closing it while focusing this
         * input for the first time.
         *
         * Note the modal's visibility is only handled by onFocus because modals trap the focus so
         * the onBlur won't won't be called until the modal is closed.
         */
        if (activeModalId === calendarModalKey) return

        setActiveModalId(calendarModalKey)

        handleFocus({ e, showModal: true })
      }}
      onBlur={e => {
        handleFocus({ e, showModal: false })
      }}
      className={className}
    />
  )
}

const StyledInput = styled(Input)(() => ({
  input: {
    cursor: 'pointer'
  }
}))
