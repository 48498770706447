import styled from '@emotion/styled'
import { ReactNode, useState, PropsWithChildren } from 'react'
import Icon from 'shared/library/atoms/Icon'

const DetailsList = styled.ul(({ theme }) => ({
  listStyleType: 'none',
  margin: `${theme.space[4]}px 0 0`,
  padding: 0,
  color: theme.colors.text.secondary
}))

export default DetailsList

interface DetailsListItemProps {
  expansion?: ReactNode
  showChevron?: boolean
  onClick?: () => void
}

export const DetailsListItem = ({
  expansion,
  showChevron,
  onClick,
  children
}: PropsWithChildren<DetailsListItemProps>) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <StyledDetailsListItem
      onClick={() => {
        setExpanded(!expanded)

        if (onClick) {
          onClick()
        }
      }}
    >
      {children}
      {showChevron && <StyledExpandIcon expanded={false} />}
      {expansion && <StyledExpandIcon expanded={expanded} />}
      {expansion && expanded && (
        <StyledExpansion onClick={e => e.stopPropagation()}>
          {expansion}
        </StyledExpansion>
      )}
    </StyledDetailsListItem>
  )
}

const ExpandIcon = ({ className }: { className?: string }) => (
  <Icon className={className} name="arrow-right" size={24} mr={3} />
)

interface ExpandIconProps {
  expanded: boolean
}

export const StyledExpandIcon = styled(ExpandIcon, {
  shouldForwardProp: () => false
})<ExpandIconProps>(props => ({
  transition: 'transform 0.3s',
  transform: `rotate(${props.expanded ? '90' : '0'}deg)`,
  marginLeft: 'auto',
  cursor: 'pointer'
}))

const StyledDetailsListItem = styled.li(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItem: 'center',
  marginTop: theme.space[4],
  marginBottom: theme.space[4],
  paddingTop: theme.space[4],
  '& + &': {
    ...theme.mixins.dashedLine()
  },
  '&:first-of-type': {
    marginTop: 0,
    paddingTop: 0,
    borderTop: 'none'
  },
  '&:last-of-type': {
    marginBottom: 0
  },
  width: '100%'
}))

const StyledExpansion = styled.div(({ theme }) => ({
  flexBasis: '100%',
  width: '100%',
  marginTop: theme.space[3],
  marginLeft: theme.space[3] + 24 // 24 is the size of the icon
}))
