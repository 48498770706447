import React, { useEffect, useRef, useState } from 'react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete'
import styled from '@emotion/styled'
import useWindowDimensions from 'shared/state/useWindowDimensions'
import useOnClickOutside from 'shared/state/useOnClickOutside'
import { useFormContext } from 'shared/providers/FormProvider/formProvider'
import DetailsList, { DetailsListItem } from 'library/molecules/DetailsList'
import { Text } from 'library/atoms/Typography'
import Flex from 'shared/library/atoms/Flex'
import Script from 'next/script'

import Input from 'library/atoms/Input'
export interface InputPlacesProps {
  id?: string
  placeholder: string
  name: string
  handleSelect: (lat: number, lng: number) => void
  alignMenuRight?: boolean
  showMenuAbove?: boolean
}

const PLACES_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY

export const InputPlaces = ({
  id,
  name,
  placeholder,
  handleSelect,
  alignMenuRight = false,
  showMenuAbove = false
}: InputPlacesProps) => {
  const [show, setShow] = useState(false)
  const [isMenuRight, setIsMenuRight] = useState(alignMenuRight)
  const { setValue: setFieldValue } = useFormContext()

  const menuRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLSpanElement>(null)

  const { width } = useWindowDimensions()

  useOnClickOutside({
    refs: [menuRef, buttonRef],
    onClickOutside: () => {
      setShow(false)
    },
    dependencyList: [menuRef, buttonRef, show]
  })

  // Determine if popup is at viewport's right edge, and adjust alignment appropriately
  useEffect(() => {
    const boundingRect = menuRef.current?.getBoundingClientRect()
    if (alignMenuRight || !boundingRect || !width) return

    if (!isMenuRight && boundingRect.x + boundingRect.width > width)
      setIsMenuRight(true)

    if (isMenuRight && boundingRect.x + 2 * boundingRect.width < width)
      setIsMenuRight(false)
    // TODO #6367 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef, show, width])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setShow(true)
    setValue(e.target.value)
  }

  const {
    init,
    suggestions: { data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 300
  })

  const handleLocationSelect = (suggestion: any) => {
    return async () => {
      setValue(suggestion.description, false)
      setFieldValue(name, suggestion.description)
      clearSuggestions()

      try {
        const results = await getGeocode({ address: suggestion.description })
        const { lat, lng } = await getLatLng(results[0])

        handleSelect(lat, lng)
      } catch (error) {
        console.error('Error: ', error)
      }
    }
  }

  const renderSuggestions = () =>
    data.map((suggestion: any) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion

      return (
        <StyledDetailsListItem
          onClick={handleLocationSelect(suggestion)}
          key={place_id}
        >
          <Flex flexDirection="column">
            <Text fontStyle="h6" textAlign="left">
              {main_text}
            </Text>
            <Text fontStyle="p3" color="secondary" textAlign="left">
              {secondary_text}
            </Text>
          </Flex>
        </StyledDetailsListItem>
      )
    })

  return (
    <>
      <Script
        src={`//maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&language=en&libraries=places`}
        strategy="lazyOnload"
        onReady={init}
      ></Script>

      <span ref={buttonRef}>
        <Input
          name={name}
          variant="large"
          icon="pin-map"
          onChange={handleInput}
          placeholder={placeholder}
        />
        <StyledMenu
          id={`${id}-menu`}
          role="menu"
          aria-labelledby={`${id}-button`}
          ref={menuRef}
          open={show}
          alignMenuRight={!!isMenuRight}
          showMenuAbove={!!showMenuAbove}
        >
          <DetailsList>{renderSuggestions()}</DetailsList>
        </StyledMenu>
      </span>
    </>
  )
}

const StyledMenu = styled('div', {
  shouldForwardProp: prop =>
    ['open', 'alignMenuRight', 'showMenuAbove'].every(
      transientProp => prop !== transientProp
    )
})<{
  open: boolean
  alignMenuRight: boolean
  showMenuAbove: boolean
}>(
  ({ open, theme }) => ({
    display: open ? 'flex' : 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'fit-content',
    // To space the menu below the button, regardless of its own height
    marginTop: theme.space[2],
    padding: theme.space[4],
    position: 'absolute',
    background: theme.colors.white,
    borderRadius: theme.radii[3],
    minWidth: '200px',
    zIndex: 1000
  }),
  ({ alignMenuRight }) => ({
    right: alignMenuRight ? 0 : undefined
  }),
  ({ showMenuAbove, theme }) => ({
    bottom: showMenuAbove ? theme.space[7] : undefined
  })
)

const StyledDetailsListItem = styled(DetailsListItem)(() => ({
  cursor: 'pointer'
}))
