import { ChangeEvent, FocusEvent, KeyboardEvent } from 'react'
import { useField } from 'formik'
import { KeyEvents } from 'shared/enums/keys'

export interface UseConfigureInputParams<
  HTMLTarget extends HTMLInputElement | HTMLTextAreaElement | HTMLElement,
  OnChangeParam = ChangeEvent<HTMLTarget>
> {
  name: string
  disabled?: boolean
  readOnly?: boolean
  /**
   * A boolean to define the Input as 'Externally Controlled'. This means, whilst the input may be Read Only,
   * the value of it is still able to be modified via another Input on the page. This prop ensures the label
   * behaves as expected, and not in a Read Only state.
   */
  externallyControlled?: boolean
  /**
   * It calls a function if some of the submit keys are triggered e.g. ['Enter', 'Tab']
   */
  onSubmitKeysDown?: () => void
  onChange?: (e: OnChangeParam) => void
  onBlur?: (e: FocusEvent<HTMLTarget, Element>) => void
  onFocus?: (e: FocusEvent<HTMLTarget, Element>) => void
}

/**
 * Helper for configuring and unifying our most common input interactions.
 * */
// export const useConfigureInput = <HTMLTarget extends HTMLInputElement>({
export const useConfigureInput = <
  HTMLTarget extends HTMLInputElement | HTMLTextAreaElement,
  OnChangeParam
>({
  name,
  disabled,
  readOnly,
  externallyControlled,
  onChange,
  onBlur,
  onFocus,
  onSubmitKeysDown
}: UseConfigureInputParams<HTMLTarget, OnChangeParam>) => {
  const [field, meta] = useField(name)
  const hasValue = !!(field.value || field.value === 0)
  /**
   * The hasUnsavedChanges is used for informing when a value has been modified but not submitted yet.
   *
   * Remember to reset the form on submit to inform the data has been saved.
   * @example
   * <Formik initialValues={{ name: 'John' }} onSubmit={(values, helpers) => helpers.resetForm({ values })}>
   */
  const hasUnsavedChanges = meta.initialValue !== meta.value
  /**
   * Only show the error after the input lost the focus.
   */
  const hasError = meta.touched && !!meta.error

  const isReadOnly = !!readOnly || externallyControlled

  const handleOnChange: typeof onChange = e => {
    if (onChange) onChange(e)
    field.onChange(e)
  }

  const handleOnBlur: typeof onBlur = e => {
    // Focus leave
    if (!e.currentTarget.contains(e.relatedTarget)) {
      if (onBlur) onBlur(e)
      if (field.onBlur) field.onBlur(e)
    }
  }

  const handleOnFocus: typeof onFocus = e => {
    if (onFocus) onFocus(e)
  }

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (!onSubmitKeysDown || disabled) return
    if ([KeyEvents.enter, KeyEvents.tab].some(key => key === e.key)) {
      e.preventDefault()
      onSubmitKeysDown()
    }
  }

  return {
    hasValue,
    hasUnsavedChanges,
    hasError,
    isReadOnly,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    handleOnKeyDown
  }
}
